import { useState } from "react"

const Login = ({setRestricted}) => {
  const [password, setPassword] = useState("")

  const inputHandler = (event) => setPassword(event.target.value)

  const submitHandler = (event) => {
    event.preventDefault()
    if (password === "1234") {
      setRestricted(false)
      setPassword("")
    }
  }

  return (
    <form onSubmit={submitHandler} className="text-2xl text-center p-5">
      password: <input type="password" onChange={inputHandler} value={password} className="border rounded-lg px-2 py-1 mb-2"/>
      <button className="border capitalize rounded-lg p-2 bg-blue-400 text-white font-bold hover:bg-white hover:text-blue-400 border-blue-400 m-5">submit</button>
    </form>
  )
}

export default Login