import { useState } from "react"
import { addPerson } from "../../services/people"
import OutsideClickHandler from "react-outside-click-handler"

const AddPerson = ({disable}) => {
  const [name, setName] = useState("")
  const [wins, setWins] = useState(0)
  const [losses, setLosses] = useState(0)

  const handlerGenerator = (setter) => (event) => setter(event.target.value)
  const handlerGeneratorNum = (setter) => (event) => setter(parseInt(event.target.value))

  const submitHandler = (event) => {
    event.preventDefault()
    addPerson({
      name,
      wins,
      losses
    })

    disable()
  }

  return (
    <div className="fixed left-0 top-0 bg-gray-300 grid place-items-center w-screen h-screen bg-opacity-50">
      <div className="lg:w-96 w-11/12 rounded-lg border shadow-md p-4 bg-white">
        <OutsideClickHandler onOutsideClick={disable}>
          <form onSubmit={submitHandler}>
            <h2 className="text-center block text-xl">Add Person</h2>
            <div>
              <h3 className="capitalize lg:text-xs text-base">name</h3>
              <input
                className="border rounded-lg px-2 py-1 mb-2 w-full"
                type="text"
                onChange={handlerGenerator(setName)}
                value={name}
                required
              />
            </div>
            <div className="grid lg:grid-cols-2 grid-row-2 gap-2 mb-3">
              <div>
                <h3 className="capitalize lg:text-xs text-base">wins:</h3>
                <input className="border rounded-lg px-2 py-1 mb-2 w-full" type="number" onChange={handlerGeneratorNum(setWins)} value={wins} required/>
              </div>
              <div>
                <h3 className="capitalize lg:text-xs text-base">losses: </h3>
                <input className="border rounded-lg px-2 py-1 mb-2 w-full" type="number" onChange={handlerGeneratorNum(setLosses)} value={losses} required/>
              </div>
            </div>
            <div className="text-center">
              <button className="border capitalize rounded-lg p-2 bg-blue-400 text-white font-bold hover:bg-white hover:text-blue-400 border-blue-400">submit</button>
            </div>
          </form>
        </OutsideClickHandler>
      </div>
    </div>
  )
}

export default AddPerson