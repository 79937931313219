import { round } from 'mathjs'
import { updatePerson, removePerson } from '../../services/people'
const People = ({people, editable}) => {
  const changeWins = (person, amount) => {
    const newPerson = {
      ...person,
      wins: person.wins+amount
    }
    updatePerson(newPerson)
  }
  const changeLosses = (person, amount) => {
    const newPerson = {
      ...person,
      losses: person.losses+amount
    }
    updatePerson(newPerson)
  }
  
  return (
    <div className="m-auto block text-center">
      <div className="bg-white border border-blue-400 rounded-lg inline-block m-auto text-center overflow-hidden lg:w-3/4 w-11/12 mb-5 p-4">
        <table className="table-fixed w-full text-center p-4 lg:text-2xl text-lg">
          <thead className="md:mb-5">
            <tr className="lg:text-3xl text-lg">
              <th className="w-1/7">Name</th>
              <th className="w-1/7">Wins</th>
              <th className="w-1/7">Win Rate</th>
              <th className="w-1/7">Losses</th>
              <th className="w-1/7">Loss Rate</th>
              <th className="w-1/7">Games Played</th>
            </tr>
          </thead>
          <tbody>
            {people.map(person =>
              <tr key={person.id} className="md:mb-5">
                <td className="overflow-scroll lg:overflow-hidden">
                <div className="flex justify-center items-center">
                    {
                      editable &&
                      <button className="w-8 h-8 rounded-full border-2 bg-red-400 inline-grid place-items-center" onClick={() => removePerson(person)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path d="M0 10h24v4h-24z"/></svg>
                      </button>
                    }
                    <div className="w-44">{person.name}</div>
                  </div>
                </td>
                <td>
                  <div className="flex justify-center">
                    {
                      editable &&
                      <button className="w-8 h-8 rounded-full border-2 bg-blue-400 inline-grid place-items-center" onClick={() => changeWins(person, -1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path d="M0 10h24v4h-24z"/></svg>
                      </button>
                    }
                    <span className="w-10">{person.wins}</span>
                    {
                      editable &&
                      <button className="w-8 h-8 rounded-full border-2 bg-blue-400 inline-grid place-items-center" onClick={() => changeWins(person, 1)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
                      </button>
                    }
                  </div>
                </td>
                <td>{round(1000*person.wins/(person.wins+person.losses))/10 + "%"}</td>
                <td>
                  <div className="flex justify-center">
                    {
                      editable &&
                      <button className="w-8 h-8 rounded-full border-2 bg-blue-400 inline-grid place-items-center" onClick={() => changeLosses(person, -1)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path d="M0 10h24v4h-24z"/></svg>
                      </button>
                    }
                    <span className="w-10">{person.losses}</span>
                    {
                      editable &&
                      <button className="w-8 h-8 rounded-full border-2 bg-blue-400 inline-grid place-items-center" onClick={() => changeLosses(person, 1)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
                      </button>
                    }
                  </div>
                </td>
                <td>{round(1000*person.losses/(person.wins+person.losses))/10 + "%"}</td>
                <td>{person.wins+person.losses}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default People