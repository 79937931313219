import { initializeApp } from 'firebase/app'
var config = { /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
  apiKey: "AIzaSyC-Et1_o0XrJ__9xVQvHmb8G25K0vrzufA",
  authDomain: "volleyball-wins.firebaseapp.com",
  databaseURL: "https://volleyball-wins-default-rtdb.firebaseio.com",
  projectId: "volleyball-wins",
  storageBucket: "volleyball-wins.appspot.com",
  messagingSenderId: "642065774646",
  appId: "1:642065774646:web:477404e29bcde8017a8097"
};
var fire = initializeApp(config);
export default fire;