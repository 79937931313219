import { useEffect, useState } from "react"
import Login from "./components/Login/Login"
import People from "./components/People/People"
import AddPerson from "./components/AddPerson/AddPerson"

import fire from './fire';

import { getDatabase, ref, onChildAdded, onChildChanged, onChildRemoved } from 'firebase/database';

// import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';

// import { useCollectionData } from 'react-firebase-hooks/firestore';

const App = () => {
  const [people, setPeople] = useState([])
  const [restricted, setRestricted] = useState(true)
  const [add, setAdd] = useState(false)

  // const funFunction = () => {
  //   addPerson({
  //     name: "Yay",
  //     wins: 20,
  //     loses: 25
  //   })
  // }
  
  useEffect(() => {
    const db = getDatabase(fire);
    let peopleRef = ref(db, 'people');
    // let peopleRef = fire.database().ref('people');
    onChildAdded(peopleRef, snapshot => {
      /* Update React state when message is added at Firebase Database */
      let person = { ...snapshot.val(), id: snapshot.key };
      setPeople(people => people.concat(person));
    })
    onChildChanged(peopleRef, snapshot => {
      /* Update React state when message is added at Firebase Database */
      let changedPerson = { ...snapshot.val(), id: snapshot.key };
      setPeople(people => people.map((person)  => changedPerson.id === person.id ? changedPerson : person));
    })

    onChildRemoved(peopleRef, snapshot => {
      /* Update React state when message is added at Firebase Database */
      let changedPerson = { ...snapshot.val(), id: snapshot.key };
      setPeople(people => people.filter((person) => person.id !== changedPerson.id));
    })
  }, [])

  

  return (
    <div className="bg-blue-100 min-h-screen">
      <h1 className="text-blue-400 lg:text-8xl text-center pt-5 text-5xl">Volleyball Wins!</h1>

      {restricted && <Login setRestricted={setRestricted}/>}
      {!restricted &&
        <div className="block text-center">
          <button onClick={() => setAdd(true)} className="border capitalize rounded-lg p-2 bg-blue-400 text-white font-bold hover:bg-white hover:text-blue-400 border-blue-400 mr-5 m-5 ml-5">
            Add Person
          </button>
        </div>
      }
      
      {add && <AddPerson disable={() => setAdd(false)}/>}
      <People people={people} editable={!restricted}/>
    </div>
  )
}

export default App;
