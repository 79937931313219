import fire from '../fire';

import { getDatabase, ref, push, update, remove, child } from 'firebase/database';

const db = getDatabase(fire);
let peopleRef = ref(db, 'people');

export const addPerson = (person) => {
  // fire.database().ref('people').push(person);
  push(peopleRef, person);
}

export const updatePerson = (person) => {
  // fire.database().ref('people').child(person.id).update(person);
  update(child(peopleRef, person.id), person);
}

export const removePerson = (person) => {
  // fire.database().ref('people').child(person.id).remove();
  remove(child(peopleRef, person.id));
}
